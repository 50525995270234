<template>
  <!-- Cambiar a un table -->
  <div>
    <portal-target name="notificationTraspasosSidebarAlert" />

    <!-- Lista de items -->
    <b-table
      :fields="fields"
      :items="list"
      show-empty
      responsive
      striped
      bordered
      hover
      small
      :busy="isLoading"
    >
      <template #cell(id)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item }}
        </div>
      </template>
      <template #cell(concepto)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item.concepto }}
        </div>
      </template>
      <template #cell(fecha)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
        </div>
      </template>
      <template #cell(folio)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
        </div>
      </template>
      <template #cell(mes)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="secondary" class="ml-2">
            {{ getMonthById(data.item.mes).value }}
          </b-badge>
        </div>
      </template>
      <template #cell(periodo_fiscal_id)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="primary" class="ml-2">{{ data.item.periodo_fiscal_id }}</b-badge>
        </div>
      </template>
      <template #cell(total)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="primary" class="ml-2">{{ $formatNumber(data.item.total) }}</b-badge>
        </div>
      </template>
      <template #cell(enviado)="data">
        <div class="text-primary text-center text-sm w-100" v-if="updateEnviado">
          {{ data.item.enviado?'Sí':'No' }}
        </div>
      </template>

      <!-- Muestra los detalles de los movimientos -->
      <template #row-details>
        <movimientos-list :traspaso="selectedTraspaso"></movimientos-list>
      </template>

      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
          <b-button
            class="mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="info"
            @click="
              row.toggleDetails()
              onShowMovimientos(row.item.id)
            "
          >
            Traspasos
          </b-button>

          <check-authorization :requiresAuthorizations="['importar traspasos']">
            <b-button
              class="mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="dark"
              @click="onSelect(row.item.id)"
            >
              Importar
            </b-button>
          </check-authorization>

          <check-authorization :requiresAuthorizations="['exportar traspasos']">
            <b-button
              class="mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="primary"
              @click="onExport(row.item.id, row.item.folio)"
            >
              Exportar
            </b-button>
          </check-authorization>

          <check-authorization :requiresAuthorizations="['enviado traspasos']">
            <b-button
              class="mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="green"
              @click="onEnviado(row.item.id, row.item.folio)"
            >
              SAACG.NET
            </b-button>
          </check-authorization>
          <!-- <check-authorization :requiresAuthorizations="['autorizar ampliacion y reduccion']">
            <b-button
              class="mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="success"
              @click="onAutorizar(row.item.id)"
            >
              Autorizar
            </b-button>
          </check-authorization> -->

          <u-d-controls
            editPath="traspasoModule/getById"
            deletePath="traspasoModule/deleteById"
            :id="row.item.id"
            :editPermissions="['editar claves presupuestales']"
            :deletePermissions="['eliminar traspasos']"
            double-confirmation
            :doubleConfirmationParams="{ confirmation: true }"
            @on-deleted="onDeleteItem"
          >
            <template #edit>
              <b-button
                class="btn-sm btn-outline-blue mr-3 mb-2 mb-sm-0"
                variant="white"
                v-b-toggle.traspasosFormSidebar
                @click="onEditItem(row.item.id)"
              >
                Editar
              </b-button>
            </template>
          </u-d-controls>
        </div>
      </template>
    </b-table>

    <!-- Sidebar para importar y asociar el layout a un registro de nómina -->
    <b-sidebar
      id="importTraspasoPoliza"
      right
      shadow
      backdrop
      no-header
      no-close-on-backdrop
      v-model="showSidebar"
      aria-labelledby="sidebar-no-header-title"
    >
      <template #default="{ hide }">
        <!-- Form para importar layout de nómina -->
        <div class="p-3">
          <h4 id="sidebar-no-header-title">Importar layout de traspaso</h4>
          <import v-if="Boolean(selectedTraspaso)" :traspaso="selectedTraspaso" @on-result="hide()">
            <template #btn-cancel-place="{clearForm}">
              <b-button
                variant="dark"
                size="sm"
                @click="
                  clearForm()
                  hide()
                "
              >
                Cerrar
              </b-button>
            </template>
          </import>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Import from '@/components/Traspasos/Import'
import MovimientosList from '@/components/Traspasos/MovimientosList'

const payload = () => ({
  periodo_fiscal_id: null
})

export default {
  name: 'List',

  components: {
    Import,
    MovimientosList
  },

  props: {
    mes: {
      type: Number,
      required: true
    }
  },

  async created () {
    await this.init()

    await this.loadList()
  },

  data () {
    return {
      payload: payload(),

      isLoading: false,

      list: [],

      selectedTraspaso: null,

      showSidebar: false,

      fields: ['concepto', 'fecha', 'folio', 'mes', 'total', 'enviado', 'acciones'],

      updateEnviado: true
    }
  },

  computed: {
    ...mapState('traspasoModule', ['traspasosList']),

    ...mapGetters('traspasoModule', ['getTraspasoByMonth']),

    ...mapGetters('UIModule', ['getMonthById'])
  },

  watch: {
    traspasosList: {
      immediate: true,
      deep: true,
      handler () {
        this.list = this.getTraspasoByMonth(this.mes) || []
      }
    }
  },

  methods: {
    async init () {
      const period = await this.$store.dispatch('fiscalPeriodsModule/getActiveFiscalPeriod')

      if (!period) return

      this.payload.periodo_fiscal_id = period.id
    },

    /**
     * Obtiene el listado de traspasos desde un state de Vuex.
     */
    async loadList () {
      this.isLoading = true
      const getval = await this.$store.dispatch('traspasoModule/listar', this.payload)
      this.isLoading = false

      //
      // Emite un mensaje en forma de alerta si ha ocurrido un error
      if (getval.error) {
        this.$alert(getval, 'Traspasos.', 'notificationTraspasosSidebarAlert', {
          status: 'danger'
        })
      }
    },

    onEditItem (id) {
      //
      // Manda un flag para indicar que debe esperar que el item cargue.
      this.$store.commit('traspasoModule/setWaitToEditItem', true)

      //
      // Obtiene el item desde un dispatch
      this.$store.dispatch('traspasoModule/getById', id)
    },

    onDeleteItem (data) {
      this.$alert(data, 'Traspasos.', 'notificationTraspasosSidebarAlert', {
        status: data.error ? 'danger' : 'success'
      })

      this.loadList()
    },

    onSelect (id) {
      this.selectedTraspaso = this.list.find(el => el.id === id)

      this.showSidebar = true
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch('traspasoModule/exportTraspasos', id)

      await this.$onDownload(getval, `traspaso.${folio}.xlsx`)

      this.$notify({ message: `Solicitud de descarga de traspaso con folio ${folio}.` })
    },

    async onEnviado (id, folio) {
      const response = await this.$store.dispatch('traspasoModule/setEnviado', id)
      if (response.code === 200) {
        this.updateEnviado = false
        // Actualizamos específicamente el registro por ID para no realizar una nueva consulta
        for (const idx in this.list) {
          if (this.list[idx].id === id) {
            this.list[idx].enviado = true
            this.updateEnviado = true
            break
          }
        }
      }
      this.$notify({ message: `Se actualiza traspaso con folio ${folio}.` })
    },
    // async onAutorizar (id) {
    //   this.isLoading = true
    //   const getval = await this.$store.dispatch('traspasoModule/autorizarNominaById', id)
    //   this.isLoading = false

    //   this.$alert(getval, 'Traspasos.', 'notificationTraspasosSidebarAlert', {
    //     status: getval.error ? 'danger' : 'success', closeOnTimeout: true
    //   })
    // },

    onShowMovimientos (id) {
      this.selectedTraspaso = this.list.find(el => el.id === id)
    }

    // download (data, name) {
    //   const blob = new Blob([data], { type: 'blob' })
    //   const e = document.createEvent('MouseEvents')
    //   const a = document.createElement('a')

    //   //
    //   // Nombre del archivo
    //   a.download = name

    //   a.href = window.URL.createObjectURL(blob)
    //   a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    //   e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    //   a.dispatchEvent(e)
    // }
  }
}
</script>
